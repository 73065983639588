(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('garanteasyApp')
        .constant('VERSION', "3.0.6")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('DEFAULT_BARCODE_ENCODING', "EAN13")
        .constant('ROLE_USER', "ROLE_USER")
        .constant('CMS_URI', "/cms")
;
})();
